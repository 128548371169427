import React, { useState } from "react";
import contentItems from "./data";
import { HiChevronUp } from "react-icons/hi2";
import "./dashboard.css";
import imgBio from "./img/bio.JPG";
import iconLinkedIn from "./img/linkedin.png";
import iconGithub from "./img/github.png";
import imgMountains from "./img/mountains.jpeg";
import { motion } from "framer-motion";
import { useScramble } from "use-scramble";

function getLastToken(pathname) {
  return pathname.substring(pathname.lastIndexOf("/"));
}

export default function HomePage() {
  return (
    <div className="main">
      <LeftColumn />
      <RightColumn />
    </div>
  );
}

function LeftColumn() {
  const currentPath = getLastToken(window.location.pathname);

  return (
    <div className="left-column">
      <a href="/">
        <h1>michael</h1>
      </a>
      <h3>software engineer / artist</h3>
      <div className="nav">
        <ul className="menu">
          <li className="nav-item">
            <a className={currentPath === "/" ? "selected" : ""} href="/">
              about
            </a>
          </li>
          <li className="nav-item sub-nav-container">
            <div className="sub-nav-title">
              <HiChevronUp className="chevron" /> projects
            </div>
            <ul className="sub-nav">
              {Object.keys(contentItems)
                .filter((url) => contentItems[url].type === "project")
                .map((url) => (
                  <li className="sub-nav-item">
                    <a
                      className={currentPath === url ? "selected" : ""}
                      href={url}
                    >
                      {contentItems[url].shortTitle}
                    </a>
                  </li>
                ))}
            </ul>
          </li>
          <li className="nav-item">resume</li>
          <li className="nav-item">
            <a
              className={currentPath === "/contact" ? "selected" : ""}
              href="/contact"
            >
              contact
            </a>
          </li>
        </ul>
      </div>

      <div className="footer">
        <a
          target="_blank"
          href="https://github.com/mikecolonna"
          rel="noreferrer"
        >
          <img className="icon" src={iconGithub} alt="github-icon" />
        </a>
        <a
          target="_blank"
          href="https://www.linkedin.com/in/michael-colonna/"
          rel="noreferrer"
        >
          <img className="icon" src={iconLinkedIn} alt="linkedin-icon" />
        </a>
      </div>
    </div>
  );
}

function RightColumn() {
  const content = contentItems[getLastToken(window.location.pathname)];

  return (
    <div className="right-column">
      {content.type === "bio" ? <BioShowcase /> : null}
      {content.type === "project" ? <ProjectShowcase /> : null}
      {content.type === "contact" ? <ContactForm /> : null}
    </div>
  );
}

function BioShowcase() {
  const content = contentItems[getLastToken(window.location.pathname)];
  const { ref: bodyRef } = useScramble({
    text: content.body,
    speed: 1,
    tick: 1,
    step: 8,
    seed: 5,
    playOnMount: true,
  });

  return (
    <div className="bio-showcase">
      <p className="bio-caption" ref={bodyRef} />
      <motion.div
        key="picture"
        className="right-item"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <img className="bio-img" src={imgBio} alt="bio-image" />
      </motion.div>
    </div>
  );
}

function ProjectShowcase() {
  const content = contentItems[getLastToken(window.location.pathname)];

  return (
    <>
      <ContentShowcase content={content} />
      {content.body ? <ContentInfo content={content} /> : null}
    </>
  );
}

function ContentShowcase({ content }) {
  return (
    <iframe
      title={content.shortTitle}
      src={content.contentUrl}
      allow="autoplay"
    />
  );
}

function ContentInfo({ content }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className={`content-info ${isOpen ? "open" : "closed"}`}
      onClick={() => setIsOpen(!isOpen)}
    >
      <div className="content-info-header">
        <h2>{content.fullTitle}</h2>
        <HiChevronUp className="content-info-chevron" />
      </div>
      <p className="content-info-body">{content.body}</p>

      <div className="tag-list">
        {content.tags?.map((tag, i) => (
          <Tag key={i} name={tag} />
        ))}
      </div>
    </div>
  );
}

function Tag({ name }) {
  return <div className="tag">{name}</div>;
}

function ContactForm() {
  const content = contentItems[getLastToken(window.location.pathname)];

  return (
    <div className="contact-form">
      <form
        name="contact"
        method="POST"
        netlify
        data-netlify="true"
        onSubmit="submit"
      >
        <div className="form-group">
          <input type="hidden" name="form-name" value="contact" />
          <p className="contact-cta">{content.body}</p>
          <input
            type="text"
            name="name"
            id="name"
            className="form-input"
            placeholder="name"
            required
          />
          <input
            type="email"
            name="email"
            id="email"
            className="form-input"
            placeholder="email"
            required
          />
          <textarea
            name="message"
            id="message"
            className="form-input"
            placeholder="message"
            required
          ></textarea>
          <button type="submit" className="button-submit">
            submit
          </button>
        </div>
      </form>
      <motion.div
        key="picture"
        className="right-item"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <img className="contact-img" src={imgMountains} alt="mountain-image" />
      </motion.div>
    </div>
  );
}
