const data = {
  "/": {
    type: "bio",
    shortTitle: "Michael Colonna",
    body: `Michael Colonna is a Brooklyn-based software engineer. He uses code and simple geometries to explore how software design enables creativity. He is particularly interested in the potential of technology to shape society and unlock new forms of creative expression.

He currently works as a software engineer at Uber, supporting some of the most critical backend services that power UberEats.
    `,
    contentUrl: "",
  },
  "/sankhara": {
    type: "project",
    shortTitle: "sankhara",
    fullTitle: "sankhara: a meditation on the void (2024)",
    body: `
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque consequat pellentesque mollis. Donec varius sollicitudin lorem sed lobortis. Nullam quis erat eleifend, sagittis eros eu, pharetra erat. Ut ex magna, porta et est vel, congue varius quam. Pellentesque ac vulputate libero. Duis et ligula aliquam, porta nibh eu, ornare ante. Vestibulum eget ornare nisi, et blandit nisl. Sed sed commodo nisi. Proin euismod velit eget volutpat auctor. Integer porta diam tellus, sed rutrum nisl venenatis laoreet.

    Cras vitae metus pretium, consectetur lacus blandit, faucibus erat. Donec congue a leo ac lobortis. Duis vitae velit eget felis imperdiet eleifend. Vivamus in diam tellus. Curabitur aliquam risus in velit eleifend, ac scelerisque orci dictum. Duis id sagittis mauris. Integer id urna blandit, vestibulum tellus et, commodo velit. Fusce sed elit vehicula, fermentum diam eget, bibendum nunc. Praesent sodales ligula nibh, a eleifend enim pellentesque at. Praesent sem massa, consectetur eget orci eu, ultrices eleifend erat. Curabitur in enim non nibh porta laoreet.
    
    Donec sed rutrum eros, et mattis quam. Fusce vestibulum felis lectus, consequat feugiat urna vulputate at. Aliquam ut molestie nisi. Curabitur id nibh in justo fermentum sodales ac in odio. Praesent pulvinar vel purus eu dapibus. Mauris mollis, mauris ac lobortis feugiat, sem lorem semper dolor, aliquam laoreet orci eros nec est. Nullam at molestie purus. Quisque bibendum, dolor consectetur interdum dignissim, lectus justo aliquet metus, quis ullamcorper justo eros ac neque. Phasellus felis ligula, egestas non massa ut, iaculis dignissim enim. Curabitur justo sem, fermentum lobortis magna quis, tincidunt lobortis massa.

    `,
    contentUrl: "https://particle-experiment.glitch.me/",
    tags: ["p5.js", "audacity", "web audio api", "midjourney"],
  },
  "/synesthesia": {
    type: "project",
    shortTitle: "synesthesia",
    fullTitle: "synesthesia: a musical instrument (2023)",
    body: `
I can't play an instrument. But I wish that I could. So I built an instrument that uses simple physics and a dead-simple visual interface to compose songs in physical, 2-D space. 
Try it. As someone who is not a musician, it makes me smile every time I open it up.
    `,
    contentUrl: "https://synesthetic-synth.glitch.me/",
    tags: ["p5.js", "rnbo", "web audio api"],
  },
  "/simulacra": {
    type: "project",
    shortTitle: "simulacra",
    fullTitle: "simulacra: a virtual reality experience (2019)",
    body: `
    I'm addicted to my phone, just like everyone else. 
In this piece, I tried to capture the disembodied experience of scrolling by making the digital world embodied and concrete, through animation and mechanical foley. Do our digital spaces count as "Real", as valid spaces for us to inhabit?
This piece was meant to be shown at Brown University's CAVE, a four-sided cube made out of projector screens that creates an immersive VR environment in physical space.   

`,
    contentUrl:
      "https://drive.google.com/file/d/1CNmHM3HYzD6SdkCjwMqXOkJZgoZ-jznB/preview",
    tags: ["cave"],
  },
  "/contact": {
    type: "contact",
    shortTitle: "contact",
    body: `Let's make something cool together.`,
  },
};

export default data;
